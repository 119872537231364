<template>
  <main v-if="stats">
    <h1>Your affiliation plan</h1>
    <section v-if="stats.offers">
      <b>{{stats.offers.description.en}}</b> (code: {{stats.offers.affiliateCodes.join(', ')}})<br/>
      <b>Price:</b> ${{stats.offers.price}}. <b>Your fee:</b> {{stats.offers.affiliateFee * 100}}%
    </section>
    <section v-else><b>There's no active affiliate plan.</b></section>

    <div class="stats">
      <div class="stat">
        <small>Next payment</small>
        <div>${{stats.total}}</div>
      </div>
      <div class="stat">
        <small># Paying</small>
        <div>{{stats.stats.paying}}</div>
      </div>
      <div class="stat">
        <small># Trial</small>
        <div>{{stats.stats.trial}}</div>
      </div>
      <div class="stat">
        <small># Trial ended</small>
        <div>{{stats.stats.trialEnded}}</div>
      </div>
      <div class="stat">
        <small># Cancelled</small>
        <div>{{stats.stats.cancelled}}</div>
      </div>
    </div>

    <DataGridTable
        v-if="stats"
        :data="stats.usersList"
        :hovered-columns="true"
        key="divTable"
        :default-sort-direction="-1"
        :dontSort="true"
        :default-sort="sortKey"
        :height-elements="['dividendFilter']"
        :fixed-col="0"
    >
      <template #thead="{thClick, sortKey, sortDirection}">
        <HeadCell v-for="(th, thIndex) in headers"
                  v-show="th.showCol || true"
                  :sortKey="sortKey"
                  :blockSort="true"
                  :key="`th-${thIndex}`"
                  :center="thIndex > 0"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortKey"
                  @sort="thClick(th.sortKey)">
          {{ th.label }}
        </HeadCell>
      </template>
      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`">
          <td>
            {{row.firstName}}
          </td>
          <td>
            {{row.lastName}}
          </td>
          <td :class="setStatusColor(row.status)">
            {{row.status}}
          </td>
          <td>
            {{row.signedUpAtFormat}}
          </td>
          <td>
            {{row.lastLoginAtFormat}}
          </td>
          <td>
            {{row.payingSince ? row.payingSinceFormat : 'N\A'}}
          </td>
        </tr>
      </template>
    </DataGridTable>

  </main>
</template>

<script>

import UserService from '../services/user';
import DataGridTable from "../components/dataGrid/DataGridTable";
import HeadCell from "../components/dataGrid/HeadCell";

export default {
  name: "AffiliateView",
  components: {HeadCell, DataGridTable},
  data () {
    return {
      stats: null,
      sortKey: 'signedUpAt',
      sortDirection: -1,
      headers: [{
        label: 'First name',
        sortValue: 'firstName'
      }, {
        label: 'Last name',
        sortValue: 'lastName'
      }, {
        label: 'Status',
        sortValue: 'status'
      }, {
        label: 'Signup date',
        sortValue: 'signedUpAt'
      }, {
        label: 'Last login',
        sortValue: 'lastLoginAt'
      }, {
        label: 'Paying since',
        sortValue: 'payingSince'
      }]
    }
  },
  methods: {
    setStatusColor(status) {

      let className = null;
      switch (status) {

        case 'Trial period':
          className = 'trial';
          break;

        case 'Trial ended':
          className = 'trialEnded';
          break;

        case 'Cancelled':
          className = 'cancelled';
          break;


        case 'Paying user':
          className = 'paying';
          break;
      }

      return className;
    },
    async loadStats () {

      this.stats = await UserService.getAffiliateStats();
    },
  },
  mounted() {

    this.loadStats().then().catch();
  }
}
</script>

<style scoped lang="scss">

h1 {
  margin: 0;
  padding: 0 .5rem 0 1rem;
}

section {
  padding: 0 .5rem 0 1rem;
}

.stats {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  .stat{
    background: var(--tableHoverColorOpacity);
    padding: 1rem;
    border-radius: 8px;
    width: 20%;
    div {
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.trial {
  background: var(--lightYellowOpacity) !important;
  color: var(--lightYellow);
}

.cancelled, .trialEnded {
  background: var(--opacityRed) !important;
  color: var(--red);
}

.paying {
  background: var(--mbpGreenOpacity) !important;
  color: var(--mbpGreen);
}
</style>
